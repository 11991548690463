import React from 'react';
import { CheckSquareOutlined, CheckOutlined, SafetyCertificateFilled  } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
const IntroducePage = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Block 1 */}
      <div className="bg-home">
        <div className='box1 px-4 py-24 flex flex-col items-center'>
          <div className='lg:flex items-center'>
            <div className='lg:w-10/12 lg:mr-12'>
              <span className='text-white text-4xl mb-5 font-semibold'>{t('intro_block1_1')}</span>
              <p className='text-white text-xl pt-2 pb-2'>{t('intro_block1_2')}</p>
              <a
                href='/trade'
                className=' text-black hover:text-white py-3 mb-4 font-semibold px-8 text-md bg-[#DEB00B] rounded-lg'
              >
                {t('intro_block1_3')}
              </a>
            </div>
            <div className='flex pt-10 justify-center text-center items-center'>
              <img className='w-1/2' src='/images/hero.png' alt='' style={{opacity:"0"}}/>
            </div>
          </div>
        </div>
      </div>

      {/* Block 2 */}

      <div>
        <div className='bg-[#011021] px-2 py-6 lg:flex lg:justify-evenly'>
          <div>
            <span className='text-white text-xl'>
              <span className='text-yellow-400 font-semibold'>{t('intro_block2_2')}</span>
            </span>
            <div className='flex mt-4 flex-col text-white'>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_3')}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_4')}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_5')}{' '}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_6')}{' '}
              </span>
              <span className='text-base'>
                <CheckSquareOutlined className='mr-2' />
                {t('intro_block2_7')}
              </span>
            </div>
          </div>

          <div className='lg:w-1/3'>
            <iframe
              className='w-full h-72 my-4'
              src='https://www.tradingview-widget.com/embed-widget/crypto-mkt-screener/?locale=en#%7B%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22defaultColumn%22%3A%22overview%22%2C%22screener_type%22%3A%22crypto_mkt%22%2C%22displayCurrency%22%3A%22USD%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22market%22%3A%22crypto%22%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22avatrader1.com%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22cryptomktscreener%22%7D'
            />
          </div>
        </div>
      </div>

      {/* Block 3 */}

      <div className='mt-0.5 bg-[#011021] flex flex-col justify-center items-center text-center px-2 py-16'>
        <span className='font-bold text-white text-xl'>{t('intro_block3_1')}</span>

        <div className='flex flex-col lg:flex-row justify-evenly items-center'>
          <div className='px-4 lg:w-1/4'>
            <span className='text-base text-yellow-400 font-semibold'>{t('intro_block3_2')} </span>
            <p className='text-base my-4 text-white font-semibold'>{t('intro_block3_3')} </p>
          </div>
          <img className='w-8/12 my-10 lg:w-2/12' src='/images/defi.png' alt='' />
        </div>

        {/*<div className='lg:flex hidden px-80'>
          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_2.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Forex</span>
            <span className='text-white mb-4'>{t('intro_block4_1')} </span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_3.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>CFD Stocks</span>
            <span className='text-white mb-4'>{t('intro_block4_2')}</span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_4.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Cryptocurrencies</span>
            <span className='text-white mb-4'>{t('intro_block4_3')}</span>
          </div>
        </div>

        <div className='lg:flex hidden px-80'>
          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_5.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Commodities</span>
            <span className='text-white mb-4'>{t('intro_block4_4')} </span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_6.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>ETFs</span>
            <span className='text-white mb-4'>{t('intro_block4_5')} </span>
          </div>

          <div className='flex flex-col w-1/3 items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_7.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Indices</span>
            <span className='text-white mb-4'>{t('intro_block4_6')} </span>
          </div>
        </div>

        <div className='flex lg:hidden'>
          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_2.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Forex</span>
            <span className='text-white mb-4'>{t('intro_block4_1')} </span>
          </div>

          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_3.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>CFD Stocks</span>
            <span className='text-white mb-4'>{t('intro_block4_2')}</span>
          </div>
        </div>

        <div className='flex lg:hidden'>
          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_4.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Cryptocurrencies</span>
            <span className='text-white mb-4'>{t('intro_block4_3')}</span>
          </div>

          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_5.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Commodities</span>
            <span className='text-white mb-4'>{t('intro_block4_4')} </span>
          </div>
        </div>

        <div className='flex lg:hidden'>
          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_6.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>ETFs</span>
            <span className='text-white mb-4'>{t('intro_block4_5')}</span>
          </div>

          <div className='flex flex-col w-1/2 justify-center items-center text-center mx-2'>
            <img className='rounded-2xl w-64' src='/images/group_img_7.jpg' alt='' />
            <span className='text-yellow-400 mb-4 text-lg font-semibold'>Indices</span>
            <span className='text-white mb-4'>{t('intro_block4_6')}</span>
          </div>
        </div>*/}
      </div>

      {/* Block 4 */}

      <div className='mt-0.5 px-4 py-12 bg-[#011021] flex flex-col lg:flex-row justify-center items-center'>
        <img className='w-8/12 lg:w-1/3 hidden lg:block' src='/images/why.png' alt='' />

        <div className='lg:pl-24'>
          <span className='text-white text-xl pb-4'>{t('intro_block5_1')}</span>

          <div className='flex my-4 items-center'>
          <svg viewBox="64 64 896 896" focusable="false" data-icon="safety-certificate" width="2em" height="2em" fill="#fff" aria-hidden="true"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM694.5 340.7L481.9 633.4a16.1 16.1 0 01-26 0l-126.4-174c-3.8-5.3 0-12.7 6.5-12.7h55.2c5.1 0 10 2.5 13 6.6l64.7 89 150.9-207.8c3-4.1 7.8-6.6 13-6.6H688c6.5.1 10.3 7.5 6.5 12.8z"></path></svg>
            <div className='text-white ml-4 mt-2'>
              <span className='font-semibold text-base'>{t('intro_block5_2')}</span>
              <p className='font-semibold'>{t('intro_block5_3')} </p>
            </div>
          </div>

          <div className='flex my-4 items-center'>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="safety-certificate" width="2em" height="2em" fill="#fff" aria-hidden="true"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM694.5 340.7L481.9 633.4a16.1 16.1 0 01-26 0l-126.4-174c-3.8-5.3 0-12.7 6.5-12.7h55.2c5.1 0 10 2.5 13 6.6l64.7 89 150.9-207.8c3-4.1 7.8-6.6 13-6.6H688c6.5.1 10.3 7.5 6.5 12.8z"></path></svg>
            <div className='text-white ml-4 mt-2'>
              <span className='font-semibold text-base'>{t('intro_block5_4')}</span>
              <p className='font-semibold'>{t('intro_block5_5')} </p>
            </div>
          </div>

          <div className='flex my-4 items-center'>
            <svg viewBox="64 64 896 896" focusable="false" data-icon="safety-certificate" width="2em" height="2em" fill="#fff" aria-hidden="true"><path d="M866.9 169.9L527.1 54.1C523 52.7 517.5 52 512 52s-11 .7-15.1 2.1L157.1 169.9c-8.3 2.8-15.1 12.4-15.1 21.2v482.4c0 8.8 5.7 20.4 12.6 25.9L499.3 968c3.5 2.7 8 4.1 12.6 4.1s9.2-1.4 12.6-4.1l344.7-268.6c6.9-5.4 12.6-17 12.6-25.9V191.1c.2-8.8-6.6-18.3-14.9-21.2zM694.5 340.7L481.9 633.4a16.1 16.1 0 01-26 0l-126.4-174c-3.8-5.3 0-12.7 6.5-12.7h55.2c5.1 0 10 2.5 13 6.6l64.7 89 150.9-207.8c3-4.1 7.8-6.6 13-6.6H688c6.5.1 10.3 7.5 6.5 12.8z"></path></svg>
            <div className='text-white ml-4 mt-2'>
              <span className='font-semibold text-base'>{t('intro_block5_6')}</span>
              <p className='font-semibold'>{t('intro_block5_7')} </p>
            </div>
          </div>
        </div>

        <img className='w-8/12 lg:w-1/3 lg:hidden' src='/images/why_img1.png' alt='' />
      </div>

      {/* Block 5 */}

      <div className='flex bg-[#011021] flex-col py-16 justify-center items-center text-center mt-0.5'>
        <span className='text-white text-md font-semibold text-2xl px-24'>{t('intro_block6_1')} </span>

        <div className='flex text-white justify-center items-center mt-10'>
          <div className='flex flex-col justify-center items-center text-center w-1/3 mx-4'>
            <img src='/images/safeguard.png' alt='' style={{width:"130px",marginBottom:"10px"}}/>
            <span className='font-bold'>{t('intro_block6_2')}</span>
            <span className='font-semibold'>{t('intro_block6_3')}</span>
          </div>

          <div className='flex flex-col justify-center items-center text-center w-1/3 mx-4'>
            <img src='/images/trust.png' alt='' style={{width:"130px",marginBottom:"10px"}}/>
            <span className='font-bold'>{t('intro_block6_4')}</span>
            <span className='font-semibold'>{t('intro_block6_5')} </span>
          </div>

          <div className='flex flex-col justify-center items-center text-center w-1/3 mx-4'>
            <img src='/images/fast.png' alt='' style={{width:"130px",marginBottom:"10px"}}/>
            <span className='font-bold'>{t('intro_block6_6')}</span>
            <span className='font-semibold'>{t('intro_block6_7')} </span>
          </div>
        </div>

        <a
          href='/trade'
          className='bg-yellow-400 px-12 py-4 mt-6 rounded-2xl font-semibold text-black hover:text-white'
        >
          {t('intro_block6_8')}
        </a>
      </div>

      {/* Block 6 

      <div className='flex flex-col justify-center items-center bg-[#011021] mt-0.5 py-16 text-white'>
        <span className='text-2xl pb-10'>{t('payment_method')}</span>
        <div className='flex flex-wrap justify-center'>
          <img className='mx-1' src='/images/payment1.svg' alt='' />
          <img className='mx-1' src='/images/payment2.svg' alt='' />
          <img className='mx-1' src='/images/payment3.svg' alt='' />
          <img className='mx-1' src='/images/payment4.svg' alt='' />
          <img className='mx-1' src='/images/payment5.svg' alt='' />
          <img className='mx-1' src='/images/payment6.svg' alt='' />
          <img className='mx-1' src='/images/payment7.svg' alt='' />
          <img className='mx-1' src='/images/payment8.svg' alt='' />
          <img className='mx-1' src='/images/payment9.svg' alt='' />
          <img className='mx-1' src='/images/payment10.svg' alt='' />
          <img className='mx-1' src='/images/payment11.svg' alt='' />
          <img className='mx-1' src='/images/payment12.svg' alt='' />
          <img className='mx-1' src='/images/payment13.svg' alt='' />
          <img className='mx-1' src='/images/payment14.svg' alt='' />
        </div>
      </div>*/}

      {/* Block 7 */}

      <div className='flex px-4 flex-col text-white justify-center text-center items-center bg-[#011021] mt-0.5 py-8'>
        <img className='w-1/4 lg:w-1/6' src={require('../assets/images/logo.png')} alt='logo' />
        <span className='my-2'>{t('footer1')} </span>
        <p>{t('footer2')}</p>
        <p className='my-2'>{t('footer3')}</p>
        <p>{t('footer4')}</p>
        <p className='text-yellow-400'>{t('footer5')}</p>
      </div>
    </>
  );
};

export default IntroducePage;
